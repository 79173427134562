
// export default BlogCreate;
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import config from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageUploader from "react-quill-image-uploader";

import "./styles.css";

const BlogCreate = () => {
  const pageTitle = "Add New Blog";
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [content, setContent] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    // Fetch blog data logic
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/blog`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setValue("blog_title", data.blog_title);
          setContent(data.blog_content);
        } else {
          toast.error("Error fetching blog data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [setValue]);

  const onSubmit = async (data) => {
    try {
      const response = await fetch(`${config.apiUrl}/blog/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
          blog_content: content,
          video_url: videoUrl,
          image_url: imageUrl,
        }),
      });

      if (response.ok) {
        toast.success("Blog added successfully");
        navigate("/blogs");
      } else {
        toast.error("Error adding blog");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleImageUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file);

      const response = await fetch(`${config.apiUrl}/upload`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setImageUrl(data.url);
      } else {
        toast.error("Error uploading image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image");
    }
  };

  const handleVideoUrlChange = (e) => {
    setVideoUrl(e.target.value);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
  ];

  return (
    <div className="content">
      <div className="card col-md-12 m-auto p-30">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-15">
              <label
                htmlFor="category"
                className="col-sm-4 col-form-label text-right"
              >
                Category
              </label>
              <div className="col-sm-3">
                <input
                  type="text"
                  name="category"
                  className="form-control"
                  {...register("category", { required: true })}
                />
                {errors.category && (
                  <span className="text-danger">Category is required.</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="title"
                className="col-sm-4 col-form-label text-right"
              >
                Title
              </label>
              <div className="col-sm-3">
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  {...register("title", { required: true })}
                />
                {errors.title && (
                  <span className="text-danger">Title is required.</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="subtitle"
                className="col-sm-4 col-form-label text-right"
              >
                Subtitle
              </label>
              <div className="col-sm-3">
                <input
                  type="text"
                  name="subtitle"
                  className="form-control"
                  {...register("subtitle", { required: true })}
                />
                {errors.subtitle && (
                  <span className="text-danger">Subtitle is required.</span>
                )}
              </div>
            </div>

            {/* <div className="row mb-15">
              <label
                htmlFor="link"
                className="col-sm-4 col-form-label text-right"
              >
                Link
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="link"
                  className="form-control"
                  {...register("link", { required: true })}
                />
                {errors.link && (
                  <span className="text-danger">Link is required.</span>
                )}
              </div>
            </div> */}

            <div className="row mb-15">
              <label
                htmlFor="mediaType"
                className="col-sm-4 col-form-label text-right"
              >
                Media Type
              </label>
              <div className="col-sm-3">
                <select
                  name="mediaType"
                  className="form-control"
                  {...register("mediaType", { required: true })}
                >
                  <option value="">Select Media Type</option>
                  <option value="image">Image</option>
                  <option value="video">Video</option>
                  <option value="url">URL</option>
                </select>
                {errors.mediaType && (
                  <span className="text-danger">Media Type is required.</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="mediaFile"
                className="col-sm-4 col-form-label text-right"
              >
                {watch('mediaType') === 'url' ? 'Work URL' : `Upload ${watch('mediaType')}`}
              </label>
              <div className="col-sm-3">
                {watch('mediaType') === 'url' ? (
                  <input
                    type="text"
                    name="mediaFile"
                    className="form-control"
                    placeholder="Work URL"
                    {...register('mediaFile', { required: true })}
                  />
                ) : (
                  <input
                    type="file"
                    name="mediaFile"
                    className="form-control"
                    onChange={handleImageUpload}
                  />
                )}
                {errors.mediaFile && (
                  <span className="text-danger">File is required.</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="blog_title"
                className="col-sm-4 col-form-label text-right"
              >
                Blog Title
              </label>
              <div className="col-sm-3">
                <input
                  type="text"
                  name="blog_title"
                  className="form-control"
                  {...register("blog_title", { required: true })}
                />
                {errors.blog_title && (
                  <span className="text-danger">Blog Title is required.</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="blog_content"
                className="col-sm-4 col-form-label text-right"
              >
                Blog Content
              </label>
              <div className="col-sm-8">
                <ReactQuill
                  value={content}
                  onChange={(value) => setContent(value)}
                  modules={modules}
                  formats={formats}
                />

                {errors.blog_content && (
                  <span className="text-danger">
                    Blog Content is required.
                  </span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="created_on"
                className="col-sm-4 col-form-label text-right"
              >
                Created On
              </label>
              <div className="col-sm-3">
                <input
                  type="text"
                  name="created_on"
                  className="form-control"
                  {...register("created_on", { required: true })}
                />
                {errors.created_on && (
                  <span className="text-danger">Created On is required.</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="created_by"
                className="col-sm-4 col-form-label text-right"
              >
                Created By
              </label>
              <div className="col-sm-3">
                <input
                  type="text"
                  name="created_by"
                  className="form-control"
                  {...register("created_by", { required: true })}
                />
                {errors.created_by && (
                  <span className="text-danger">Created By is required.</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <div className="col-sm-12 text-center">
                <button
                  className="btn btn-primary btn-round mr-5"
                  type="submit"
                >
                  Submit
                </button>
                <Link
                  to="/user-management/blogs"
                  className="btn btn-primary btn-round"
                  type="button"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BlogCreate;

