import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(`${config.apiUrl}/auth/login`, data);
      const responseData = response.data;
      // console.log(responseData.status + "Sachin J");
      if (responseData.status == "success") {
        // Store response data in session or local storage
        sessionStorage.setItem("user", JSON.stringify(responseData.user));
        sessionStorage.setItem("token", responseData.token);
        console.log(responseData.token);

        // Navigate to the dashboard route
        navigate("/dashboard");
      } else {
        // console.log(data)
        toast.error(response.data.error);
      }
    } catch (error) {
      // console.log(error);
      toast.error("Logged out successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="container h-p100">
      <div className="row align-items-center justify-content-md-center h-p100">
        <div className="col-lg-8 col-md-11 col-12">
          <div
            className="row align-items-center justify-content-md-center h-p100"
            style={{ marginTop: "250px" }}
            data-overlay-light="9"
          >
            <div className="col-lg-5 col-md-6 col-12">
              <div className="p-40 text-center content-bottom">{/* ... */}</div>
            </div>
            <div className="col-lg-7 col-md-6 col-12">
              <div className="p-20 content-bottom">
                {/* ... */}
                <form method="post" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        id="email"
                        placeholder="Username"
                        // value={email} // Bind to state variable
                        onChange={(e) => setEmail(e.target.value)}
                        {...register("email", {
                          required: "Please enter your email.",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Please enter a valid email address.",
                          },
                        })}
                      />
                      {errors.email && (
                        <div className="invalid-feedback">
                          {errors.email.message}
                        </div>
                      )}
                      {/* ... */}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        className={`form-control ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        id="password"
                        placeholder="Password"
                        // value={password} // Bind to state variable
                        onChange={(e) => setPassword(e.target.value)}
                        {...register("password", {
                          required: "Please enter your password.",
                        })}
                      />
                      {errors.password && (
                        <div className="invalid-feedback">
                          {errors.password.message}
                        </div>
                      )}
                      {/* ... */}
                    </div>
                  </div>
                  {/* ... */}
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-danger btn-block margin-top-10"
                    >
                      SIGN IN
                    </button>
                  </div>
                </form>
                {/* ... */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
