import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import config from '../../../config';
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify"; // Import react-toastify
import Common from "../../common/Common";

const ListWorks = () => {
  const [ourWorks, setOurWorks] = useState([]);

  useEffect(() => {
    fetchOurWorks(); // Fetch contact requests on component mount
  }, []);

  const fetchOurWorks = () => {
    axios.get(`${config.apiUrl}/work`)
      .then(response => {
        setOurWorks(response.data);
      })
      .catch(error => {
        console.error('Error fetching contact requests:', error);
      });
  };

  const handleDelete = (id) => {
    // Show the confirm dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, proceed with deletion
        axios.delete(`${config.apiUrl}/work/${id}`)
          .then(response => {
            console.log('Item deleted successfully:', response.data);
            fetchOurWorks(); // Refresh the table data
            // Show success message
            toast.success("Item deleted successfully");
            // Swal.fire("Deleted!", "Your item has been deleted.", "success");
          })
          .catch(error => {
            console.error('Error deleting item:', error);
            // Handle error, show error message, etc.
            toast.error("An error occurred while deleting");
            // Swal.fire("Error", "An error occurred while deleting.", "error");
          });
      }
    });
  };

  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Our Works</h3>
              <div class="timeline-footer text-right">
                <Link to="/our-works/add" class="btn btn-primary btn-round btn-sm">Add Work</Link>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Type</th>
                      <th>Client Name</th>
                      <th>Title</th>
                      <th>Short Description</th>
                      <th>Created On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ourWorks.map((request, index) => (
                      <tr key={request._id}>
                        <td>{index + 1}</td>
                        <td>{request.type}</td>
                        <td>{request.client_name}</td>
                        <td>{request.title}</td>
                        <td>{request.short_description}</td>
                        <td>{Common.formatDatePM(request.created_on)}</td>
                        <td>
                          <Link to={`/our-works/${request.slug}`} className="mr-2"><i className="fa fa-eye"></i></Link>
                          <Link to={`/our-works/update/${request.slug}`} className="mr-2"><i className="fa fa-edit"></i></Link>
                          <Link onClick={() => handleDelete(request._id)}><i className="fa fa-trash"></i></Link>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListWorks;