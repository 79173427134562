// import React, { useState } from "react";
import { useForm } from "react-hook-form";
// import axios from "axios";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import config from '../../../../config';
// import DatePicker from "react-datepicker"; // Import react-datepicker
import "react-datepicker/dist/react-datepicker.css"; // Import styles
// import { ToastContainer, toast } from "react-toastify";

const CreateRole = () => {
  const pageTitle = "Add New Role";
  // const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // const [selectedDate, setSelectedDate] = useState(null); // State for the selected date
  // const userData = sessionStorage.getItem("user");
  // const user = userData ? JSON.parse(userData) : null;

  const onSubmit = (data) => {
    // data.project_date = selectedDate;
    // data.created_by = user.name;
    // data.status = "active";
    // axios.post(`${config.apiUrl}/our-work`, data)
    //   .then(response => {
    //     // console.log('Work added successfully:', response.data);
    //     toast.success("Work added successfully");
    //     navigate('/our-works');
    //     // Clear the form or show a success message as needed
    //   })
    //   .catch(error => {
    //     console.error('Error adding work:', error);
    //     // Handle error, show error message, etc.
    //   });
  };
  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">{pageTitle}</h3>
              <div className="timeline-footer text-right">
                <Link
                  to="/our-works/add"
                  className="btn btn-primary btn-round btn-sm"
                >
                  Add Role
                </Link>
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Role Name
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      {...register("name", { required: true })}
                    />
                    {errors.type && (
                      <span className="text-danger">
                        Role Name is required.
                      </span>
                    )}
                  </div>
                </div>

                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Status
                  </label>
                  <div className="col-sm-3">
                    <select
                      name="status"
                      {...register("type")}
                      className="form-control"
                    >
                      <option value={"Active"}>Active</option>
                      <option value={"Inactive"}>Inactive</option>
                    </select>
                  </div>
                </div>

                <div className="row mb-15">
                  <div className="col-sm-12 text-center">
                    <button
                      className="btn btn-primary btn-round mr-5"
                      type="submit"
                    >
                      Submit
                    </button>
                    <Link
                      to="/user-management/roles"
                      className="btn btn-primary btn-round"
                      type="reset"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateRole;
