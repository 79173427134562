import React from 'react';
import AppRoutes from './components/Routes';
import "react-toastify/dist/ReactToastify.css"; // Import react-toastify styles
import './assets/assets/vendor_components/bootstrap/dist/css/bootstrap.min.css';
import './assets/css/bootstrap-extend.css';
import './assets/css/master_style.css';
import './assets/css/skins/_all-skins.css';
// import $ from 'jquery';
// import './assets/js/pages/dashboard.js';
// import './assets/assets/vendor_components/jquery/dist/jquery.js';
// import './assets/assets/vendor_components/jquery-ui/jquery-ui.min.js'
// import './assets/assets/vendor_components/popper/dist/popper.min.js';
// import './assets/assets/vendor_components/bootstrap/dist/js/bootstrap.js';
// import './assets/assets/vendor_components/raphael/raphael.min.js';
// import './assets/assets/vendor_components/morris.js/morris.min.js';
// import './assets/js/template.js';
// import './assets/js/demo.js';

function App() {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
