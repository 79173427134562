import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import config from '../../../config';
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import EditBlog from './EditBlog'; // Update the path accordingly
import Common from "../../common/Common";
import { Button } from "react-bootstrap";
import PreviewBlog from "./PreviewBlog";

const ListBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [editBlog, setEditBlog] = useState(null);
  const location = useLocation();
  const [previewBlog, setPreviewBlog] = useState(null);

  useEffect(() => {
    fetchBlogs(); // Fetch blogs on component mount
  }, []);

  useEffect(() => {
    // Check if there is submitted data in the state
    const submittedData = location.state?.submittedData;
    if (submittedData) {
      // Do something with the submitted data (if needed)
      console.log('Submitted data from BlogCreate:', submittedData);
      // Optionally, you can re-fetch blogs after a new blog is added
      fetchBlogs();
    }
  }, [location.state]);

  const fetchBlogs = () => {
    axios.get(`${config.apiUrl}/blog`)
      .then(response => {
        setBlogs(response.data);
      })
      .catch(error => {
        console.error('Error fetching blogs:', error);
      });
  };
  const handlePreview = (blog) => {
    setPreviewBlog(blog);
  };

  const handleDelete = (id) => {
    // Show the confirm dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, proceed with deletion
        axios.delete(`${config.apiUrl}/blog/${id}`)
          .then(response => {
            console.log('Blog deleted successfully:', response.data);
            // Refresh the table data
            fetchBlogs();
            // Show success message
            toast.success("Blog deleted successfully");
          })
          .catch(error => {
            console.error('Error deleting blog:', error);
            // Handle error, show error message, etc.
            toast.error("An error occurred while deleting");
          });
      }
    });
  };

  const handleEdit = (blog) => {
    setEditBlog(blog);
  };

  const handleCancelEdit = () => {
    setEditBlog(null);
  };

  return (
    <>
      <div className="content">
        <div className="card col-md-12  p-30">
          <div className="card-header">
            <h3 className="text-bold">Blogs</h3>
            <div className="timeline-footer text-right">
              <Link to="/blogs/create" className="btn btn-primary btn-round btn-sm">Add Blog</Link>
            </div>
          </div>
          <div className="card-body">
            {editBlog ? (
              <EditBlog
                blog={editBlog}
                onCancel={handleCancelEdit}
                onEditSuccess={() => {
                  // Handle success, if needed
                  fetchBlogs(); // Refresh the blog list after editing
                }}
              />
            ) : (
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Content</th>
                        <th>Created On</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {blogs.map((blog, index) => (
                        <tr key={blog._id}>
                          <td>{index + 1}</td>
                          <td>{blog.blog_title}
                          </td>
                          <td>
                            <div className="text-uppercase" dangerouslySetInnerHTML={{ __html: blog?.blog_content }} />
                          </td>
                          <td>{Common.formatDatePM(blog.created_on)}</td>
                          <td>
                            <Link to={`/blogs/view/${blog._id}`} className="mr-2"><i className="fa fa-eye" onClick={() => handlePreview(blog)}></i></Link>
                            <Link to={`/blogs/edit/${blog._id}`} className="mr-2"><i className="fa fa-edit"></i></Link>
                            <Button onClick={() => handleDelete(blog._id)}><i className="fa fa-trash"></i></Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {previewBlog && (
        <PreviewBlog
          blog={previewBlog}
          onCancel={() => setPreviewBlog(null)}
        />
      )}
    </>
  );
}

export default ListBlogs;
