import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import config from "../../../config";
import { ToastContainer, toast } from "react-toastify";

const EditBlog = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log("Blog ID:", id); // Get the blog ID from the URL params
  const [blog, setBlog] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // Fetch the blog data based on the ID when the component mounts
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/blog/${id}`);
        if (response.status === 200) {
          const data = response.data;
          setBlog(data);

          // Check if data is available before setting values
          if (data) {
            setValue("blog_title", data.blog_title);
            setValue("blog_content", data.blog_content);
          } else {
            toast.error("Blog data not found");
          }
        } else {
          toast.error("Error fetching blog data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchBlog(); // Call the function to fetch blog data
  }, [id, setValue]);

  const onSubmit = async (data) => {
    try {
      const response = await axios.put(`${config.apiUrl}/blog/${id}`, {
        blog_title: data.blog_title,
        blog_content: data.blog_content,
      });

      if (response.status === 200) {
        navigate("/user-management/blogs");
        toast.success("Blog updated successfully");
      } else {
        toast.error("Error updating blog");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!blog) {
    return <div>Loading...</div>; // Add a loading indicator if blog data is still being fetched
  }

  return (
    <>
      <div className="content">
        <div className="card col-md-12 m-auto p-30">
          <div className="card-header">
            <h3 className="text-bold">Edit Blog</h3>
            <div className="timeline-footer text-right">
              <Link
                to="/user-management/blogs"
                className="btn btn-primary btn-round btn-sm"
              >
                Back to Blogs
              </Link>
            </div>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-15">
                <label
                  htmlFor="title"
                  className="col-sm-4 col-form-label text-right"
                >
                  Blog Title
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    name="blog_title"
                    className="form-control"
                    {...register("blog_title", { required: true })}
                  />
                  {errors.blog_title && (
                    <span className="text-danger">Blog Title is required.</span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="content"
                  className="col-sm-4 col-form-label text-right"
                >
                  Blog Content
                </label>
                <div className="col-sm-8">
                  <ReactQuill
                    value={getValues("blog_content") || ""}
                    onChange={(value) => setValue("blog_content", value)}
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        ["link", "image"],
                        ["clean"],
                      ],
                    }}
                    formats={[
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "header",
                      "link",
                      "image",
                    ]}
                  />
                  {errors.blog_content && (
                    <span className="text-danger">
                      Blog Content is required.
                    </span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <div className="col-sm-12 text-center">
                  <button
                    className="btn btn-primary btn-round mr-5"
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBlog;
