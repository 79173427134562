import React from "react";
import { Link } from "react-router-dom";
import Logo from './../../assets/images/logo-light-text.png';
const Header = () => {
  const userData = sessionStorage.getItem('user');
  const user = userData ? JSON.parse(userData) : null;
  return <>
    <header className="main-header">
      {/* <!-- Logo --> */}
      <a href="index.html" className="logo">
        {/* <!-- mini logo for sidebar mini 50x50 pixels --> */}
        <b className="logo-mini">
          <span className="light-logo"><img src={Logo} alt="logo" /></span>
          <span className="dark-logo"><img src={Logo} alt="logo" /></span>
        </b>
        {/* <!-- logo for regular state and mobile devices --> */}
        <span className="logo-lg">
          <img src="../images/logo-light-text.png" alt="logo" className="light-logo" />
          <img src="../images/logo-dark-text.png" alt="logo" className="dark-logo" />
        </span>
      </a>
      {/* <!-- Header Navbar --> */}
      <nav className="navbar navbar-static-top">
        {/* <!-- Sidebar toggle button--> */}
        <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
          <span className="sr-only">Toggle navigation</span>
        </a>

        <div className="navbar-custom-menu">
          <ul className="nav navbar-nav">
            {/* <!-- Notifications --> */}
            <li className="dropdown notifications-menu">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <i className="fa fa-bell"></i>
              </a>
              <ul className="dropdown-menu scale-up">
                <li className="header">You have 7 notifications</li>
                <li>
                  {/* <!-- inner menu: contains the actual data --> */}
                  <ul className="menu inner-content-div">
                    <li>
                      <a href="#">
                        <i className="fa fa-users text-aqua"></i> Curabitur id eros quis nunc suscipit blandit.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-warning text-yellow"></i> Duis malesuada justo eu sapien elementum, in semper diam posuere.
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="footer"><a href="#">View all</a></li>
              </ul>
            </li>
            {/* <!-- Tasks --> */}
            {/* <!-- User Account --> */}
            <li className="dropdown user user-menu">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <img src="../images/user5-128x128.jpg" className="user-image rounded-circle" alt="User Image" />
              </a>
              <ul className="dropdown-menu scale-up">
                {/* <!-- User image --> */}
                <li className="user-header">
                  <img src="../images/user5-128x128.jpg" className="float-left rounded-circle" alt="User Image" />
                  <p>
                    {user ? user.name : ''}
                    <small className="mb-5">{user ? user.email : ''}</small>
                    {/* <a href="#" className="btn btn-danger btn-sm btn-rounded">View Profile</a> */}
                  </p>
                </li>
                {/* <!-- Menu Body --> */}
                <li className="user-body">
                  <div className="row no-gutters">
                    <div className="col-12 text-left">
                      <a href="#"><i className="fa fa-user"></i> My Profile</a>
                    </div>
                    <div role="separator" className="divider col-12"></div>
                    <div className="col-12 text-left">
                      <Link to="/login"><i className="fa fa-power-off"></i> Logout</Link>
                    </div>
                  </div>
                  {/* <!-- /.row --> */}
                </li>
              </ul>
            </li>
            {/* <!-- Control Sidebar Toggle Button --> */}
          </ul>
        </div>
      </nav>
    </header>

  </>
}

export default Header;