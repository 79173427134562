import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config";
import { ToastContainer, toast } from "react-toastify";
const apiUrl = config.apiUrl;

const EditJob = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // Fetch the job data based on the ID when the component mounts
    const fetchJob = async () => {
      try {
        const response = await axios.get(`${apiUrl}/job/${id}`);
        if (response.status === 200) {
          const data = response.data;
          console.log("Fetched Job Data:", data);

          setJob(data);

          // Check if data is available before setting values
          if (data) {
            setValue("title", data.title);
            setValue("designation", data.designation);
            setValue("name", data.name);
            setValue("min_experience", data.min_experience);
            setValue("max_experience", data.max_experience);
            setValue("min_salary", data.min_salary);
            setValue("max_salary", data.max_salary);
          } else {
            toast.error("Job data not found");
          }
        } else {
          toast.error("Error fetching job data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchJob(); // Call the function to fetch job data
  }, [id, setValue]);

  const onSubmit = async (data) => {
    try {
      const response = await axios.put(`${config.apiUrl}/job/${id}`, {
        title: data.title,
        designation: data.designation,
        name: data.name,
        min_experience: data.min_experience,
        max_experience: data.max_experience,
        min_salary: data.min_salary,
        max_salary: data.max_salary,
        // Add more fields for other job properties
      });
      console.log("Update Response:", response);

      if (response.status === 200) {
        navigate("/career/jobs");
        toast.success("job updated successfully");
      } else {
        toast.error("Error updating job");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!job) {
    return <div>Loading...</div>; // Add a loading indicator if job data is still being fetched
  }

  return (
    <>
      <div className="content">
        <div className="card col-md-12 m-auto p-30">
          <div className="card-header">
            <h3 className="text-bold">Edit Job</h3>
            <div className="timeline-footer text-right">
              <Link
                to="/user-management/jobs"
                className="btn btn-primary btn-round btn-sm"
              >
                Back to Jobs
              </Link>
            </div>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-15">
                <label
                  htmlFor="title"
                  className="col-sm-4 col-form-label text-right"
                >
                  Job Title
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    {...register("title", { required: true })}
                  />
                  {errors.title && (
                    <span className="text-danger">Job Title is required.</span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="designation"
                  className="col-sm-4 col-form-label text-right"
                >
                  Job Designation
                </label>
                <div className="col-sm-8">
                  <textarea
                    name="designation"
                    className="form-control"
                    {...register("designation", { required: true })}
                  />
                  {errors.designation && (
                    <span className="text-danger">
                      Job Designation is required.
                    </span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="name"
                  className="col-sm-4 col-form-label text-right"
                >
                  Name
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="text-danger">Name is required.</span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="name"
                  className="col-sm-4 col-form-label text-right"
                >
                  Min_Experience
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    name="min_experience"
                    className="form-control"
                    {...register("min_experience", { required: true })}
                  />
                  {errors.min_experience && (
                    <span className="text-danger">
                      Min_experience is required.
                    </span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="name"
                  className="col-sm-4 col-form-label text-right"
                >
                  Max_experience
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    name="max_experience"
                    className="form-control"
                    {...register("max_experience", { required: true })}
                  />
                  {errors.max_experience && (
                    <span className="text-danger">
                      Max_experience is required.
                    </span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="name"
                  className="col-sm-4 col-form-label text-right"
                >
                  Min_Salary
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    name="min_salary"
                    className="form-control"
                    {...register("min_salary", { required: true })}
                  />
                  {errors.min_salary && (
                    <span className="text-danger">Min_Salary is required.</span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="name"
                  className="col-sm-4 col-form-label text-right"
                >
                  Max_Salary
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    name="max_salary"
                    className="form-control"
                    {...register("max_salary", { required: true })}
                  />
                  {errors.max_salary && (
                    <span className="text-danger">Max_Salary is required.</span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <div className="col-sm-12 text-center">
                  <button
                    className="btn btn-primary btn-round mr-5"
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditJob;
