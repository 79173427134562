import React from "react";
import { Link } from 'react-router-dom';

const Menu = () => {
  const userData = sessionStorage.getItem('user');
  const user = userData ? JSON.parse(userData) : null;

  return <>
    <aside className="main-sidebar">
      {/* <!-- sidebar --> */}
      <section className="sidebar">
        {/* <!-- Sidebar user panel --> */}
        <div className="user-panel">
          <div className="ulogo">
            <a href="/">
              {/* <!-- logo for regular state and mobile devices --> */}
              <span><b>ViralOn </b>Admin</span>
            </a>
          </div>
          <div className="text-center">
            <i className="fa fa-user fa-3x"></i>
          </div>
          <div className="info text-uppercase">
            <p>{user ? user.name : ''}</p>
            <a href="" className="link" data-toggle="tooltip" title="" data-original-title="Settings"><i className="fa fa-cog"></i></a>
            <Link to="/logout" className="link" data-toggle="tooltip" title="" data-original-title="Logout"><i className="fa fa-power-off"></i></Link>
          </div>
        </div>
        {/* <!-- sidebar menu --> */}
        <ul className="sidebar-menu" data-widget="tree">
          <li className="nav-devider"></li>
          <li className="active">
            <Link to="/dashboard">
              <i className="fa fa-dashboard"></i> <span>Dashboard</span>
            </Link>
          </li>
          {/* <li className="treeview">
            <Link to="#">
              <i className="fa fa-th"></i>
              <span>User Management</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </Link>
            <ul className="treeview-menu">
              <li><Link to="/user-management/permissions">Permissions</Link></li>
              <li><Link to="/user-management/roles">Roles</Link></li>
              <li><Link to="/user-management/blogs">Blogs</Link></li>
              <li><Link to="/users/change-password">Change Password</Link></li>
            </ul>
          </li> */}
          {/* <li className="treeview">
            <a href="#">
              <i className="fa fa-cog"></i> <span>Settings</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li><Link to="/settings/contacts">Contacts</Link></li>
              <li><Link to="/settings/social-links">Social Links</Link></li>
            </ul>
          </li> */}
          {/* <li>
            <Link to="/contact-requests">
              <i className="fa fa-envelope"></i>
              <span>Contact Requests</span>
            </Link>
          </li> */}
          <li>
            <Link to="/blogs">
              <i className="fa fa-briefcase"></i>
              <span>Blogs</span>
            </Link>
          </li>
          <li>
            <Link to="/our-works">
              <i className="fa fa-briefcase"></i>
              <span>Our Work</span>
            </Link>
          </li>
          {/* <li>
            <Link to="/seo-content">
              <i className="fa fa-search"></i>
              <span>SEO</span>
            </Link>
          </li> */}
          {/* <li>
            <Link to="/e-book-subscribers">
              <i className="fa fa-bell-o"></i>
              <span>eBook Subscribers</span>
            </Link>
          </li> */}
          <li className="treeview">
            <a href="#">
              <i className="fa fa-cog"></i> <span>Career</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li><Link to="/career/jobs">Openings</Link></li>
              {/* <li><Link to="/settings/job-applications">Job Applications</Link></li> */}
            </ul>
          </li>
          {/* <li>
            <Link to="/blogs">
              <i className="fa fa-bell"></i>
              <span>Blog</span>
            </Link>
          </li> */}
          <li>
            <Link to="/news-letters">
              <i className="fa fa-bell"></i>
              <span>Subscribers</span>
            </Link>
          </li>
        </ul>
      </section>
    </aside>

  </>
}

export default Menu;