// Import necessary libraries and components
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import config from '../../../config';
import Common from "../../common/Common";

const ViewWork = () => {
  const [workDetails, setWorkDetails] = useState({});
  const { slug } = useParams();

  useEffect(() => {

    axios.get(`${config.apiUrl}/work/${slug}`)
      .then(response => {
        setWorkDetails(response.data);
      })
      .catch(error => {
        console.error('Error fetching work details:', error);
      });
  }, [slug]);

  return (
    <>
      <div className="content">
        <div className="card col-md-8 m-auto p-30">
          <div className="card-header">
            <h3 className="text-bold">Work Details</h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4"><strong>Type:</strong></div>
                  <div className="col-md-8"> {workDetails.type}</div>
                </div>
                <div className="row">
                  <div className="col-md-4"><strong>Title:</strong></div>
                  <div className="col-md-8"> {workDetails.title}</div>
                </div>
                <div className="row">
                  <div className="col-md-4"><strong>Description:</strong></div>
                  <div className="col-md-8"> {workDetails.description}</div>
                </div>
                <div className="row">
                  <div className="col-md-4"><strong>Short Descriotion:</strong></div>
                  <div className="col-md-8"> {workDetails.short_description}</div>
                </div>
                <div className="row">
                  <div className="col-md-4"><strong>Project Date:</strong></div>
                  <div className="col-md-8"> {Common.formatDatePM(workDetails.project_date)}</div>
                </div>
              </div>
              <div className="col-md-6">
                <img src={`https://api.viralon.in/${workDetails.images}`} alt="Work Details" style={{ width: "200px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewWork;
