// PreviewBlog.js
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import config from '../../../config';
import Common from "../../common/Common";

const PreviewBlog = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    // Fetch the blog data based on the ID when the component mounts
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/blog/${id}`);
        if (response.status === 200) {
          const data = response.data;
          setBlog(data);
        } else {
          console.error("Error fetching blog data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchBlog(); // Call the function to fetch blog data
  }, [id]);

  if (!blog) {
    return <div>Loading...</div>; // Add a loading indicator if blog data is still being fetched
  }

  return (
    <>
      <div className="content">
        <div className="card col-md-12 m-auto p-30">
          <div className="card-header">
            <h3 className="text-bold">Blog Details</h3>
            <div className="timeline-footer text-right">
              <Link to="/user-management/blogs" className="btn btn-primary btn-round btn-sm">Back to Blogs</Link>
            </div>
          </div>
          <div className="card-body">
            <div className="row mb-15">
              <label htmlFor="title" className="col-sm-4 col-form-label text-right">Blog Title</label>
              <div className="col-sm-8">
                <p>{blog.blog_title}</p>
              </div>
            </div>

            <div className="row mb-15">
              <label htmlFor="content" className="col-sm-4 col-form-label text-right">Blog Content</label>
              <div className="col-sm-8">
                <p>{blog.blog_content}</p>
              </div>
            </div>

            <div className="row mb-15">
              <label htmlFor="createdOn" className="col-sm-4 col-form-label text-right">Created On</label>
              <div className="col-sm-8">
                <p>{Common.formatDatePM(blog.created_on)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreviewBlog;
