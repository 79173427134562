import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import config from '../../../config';
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import EditJob from './EditJob'; // Update the path accordingly
import Common from "../../common/Common";
import { Button } from "react-bootstrap";
import ViewJob from "./ViewJob";

const Job = () => {
  const [jobs, setJobs] = useState([]);
  const [editJob, setEditJob] = useState(null);
  const location = useLocation();

  useEffect(() => {
    fetchJobs(); // Fetch openings on component mount
  }, []);

  useEffect(() => {
    // Check if there is submitted data in the state
    const submittedData = location.state?.submittedData;
    if (submittedData) {
      // Do something with the submitted data (if needed)
      console.log('Submitted data from AddJobs:', submittedData);
      // Optionally, you can re-fetch openings after a new opening is added
      fetchJobs();
    }
  }, [location.state]);

  const fetchJobs = () => {
    axios.get(`${config.apiUrl}/job`)
      .then(response => {
        setJobs(response.data);
      })
      .catch(error => {
        console.error('Error fetching jobs:', error);
      });
  };

  const handleDelete = (id) => {
    // Show the confirm dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, proceed with deletion
        axios.delete(`${config.apiUrl}/job/${id}`)
          .then(response => {
            console.log('Job deleted successfully:', response.data);
            // Refresh the table data
            fetchJobs();
            // Show success message
            toast.success("Job deleted successfully");
          })
          .catch(error => {
            console.error('Error deleting job:', error);
            // Handle error, show error message, etc.
            toast.error("An error occurred while deleting");
          });
      }
    });
  };

  const handleEdit = (job) => {
    setEditJob(job);
  };

  const handleCancelEdit = () => {
    setEditJob(null);
  };

  return (
    
      <>
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Jobs</h3>
              <div className="timeline-footer text-right">
                <Link to="/career/post-job" className="btn btn-primary btn-round btn-sm">Add Job</Link>
              </div>
            </div>
            <div className="card-body">
              {editJob ? (
                <EditJob
                  job={editJob}
                  onCancel={handleCancelEdit}
                  onEditSuccess={() => {
                    // Handle success, if needed
                    fetchJobs(); // Refresh the job list after editing
                  }}
                />
              ) : (
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Job Title</th>
                        <th>Designation</th>
                        <th>Min Experience</th>
                        <th>Max Experience</th>
                        <th>Min Salary</th>
                        <th>Max Salary</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobs.map((job, index) => (
                        <tr key={job._id}>
                          <td>{index + 1}</td>
                          <td>{job.name}</td>
                          <td>{job.title}</td>
                          <td>{job.designation}</td>
                          <td>{job.min_experience}</td>
                          <td>{job.max_experience}</td>
                          <td>{job.min_salary}</td>
                          <td>{job.max_salary}</td>
                          <td>
                         
                          <Link to={`/career/view/${job._id}`} className="mr-2"><i className="fa fa-eye"></i></Link>
                          <Link to={`/career/edit/${job._id}`} className="mr-2"><i className="fa fa-edit"></i></Link>
                          <Button onClick={() => handleDelete(job._id)}><i className="fa fa-trash"></i></Button>
                        </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
}

export default Job;
