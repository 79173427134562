import React from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../../../config';
import { ToastContainer, toast } from 'react-toastify';

const ViewJob = () => {
  const { id } = useParams();
  const [job, setJob] = React.useState(null);

  React.useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/job/${id}`);
        if (response.status === 200) {
          setJob(response.data);
        } else {
          toast.error('Error fetching job data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchJob();
  }, [id]);

  if (!job) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="content">
        <div className="card col-md-12 m-auto p-30">
          <div className="card-header">
            <h3 className="text-bold">View Job</h3>
            <div className="timeline-footer text-right">
              <Link to="/career/jobs" className="btn btn-primary btn-round btn-sm">
                Back to Jobs
              </Link>
            </div>
          </div>
          <div className="card-body">
            <div className="row mb-15">
              <label className="col-sm-4 col-form-label text-right">Job Title</label>
              <div className="col-sm-8">
                <span>{job.title}</span>
              </div>
            </div>

            <div className="row mb-15">
              <label className="col-sm-4 col-form-label text-right">Job Designation</label>
              <div className="col-sm-8">
                <span>{job.designation}</span>
              </div>
            </div>

            <div className="row mb-15">
              <label className="col-sm-4 col-form-label text-right">Name</label>
              <div className="col-sm-8">
                <span>{job.name}</span>
              </div>
            </div>

            <div className="row mb-15">
              <label className="col-sm-4 col-form-label text-right">Min Experience</label>
              <div className="col-sm-8">
                <span>{job.min_experience}</span>
              </div>
            </div>

            <div className="row mb-15">
              <label className="col-sm-4 col-form-label text-right">Max Experience</label>
              <div className="col-sm-8">
                <span>{job.max_experience}</span>
              </div>
            </div>

            <div className="row mb-15">
              <label className="col-sm-4 col-form-label text-right">Min Salary</label>
              <div className="col-sm-8">
                <span>{job.min_salary}</span>
              </div>
            </div>

            <div className="row mb-15">
              <label className="col-sm-4 col-form-label text-right">Max Salary</label>
              <div className="col-sm-8">
                <span>{job.max_salary}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewJob;
