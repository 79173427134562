import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill"; // Import ReactQuill
import "react-quill/dist/quill.snow.css";

import config from "../../../config";

const apiUrl = config.apiUrl;

const PostJob = () => {
  const pageTitle = "Post a New Job";
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    getValues,
  } = useForm();
  const [description, setDescription] = useState("");

  // Update description state when ReactQuill content changes
  const handleDescriptionChange = (value) => {
    setDescription(value);
    setValue("description", value); // Update form value for "description"
  };

  useEffect(() => {
    register("description", { required: true });
    setValue("description", description);
  }, [register, setValue, description]);

  const onSubmit = async (data) => {
    try {
      const response = await fetch(`${apiUrl}/job/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // Handle success
        toast.success("Job posted successfully");
        console.log("Submitted Job Data:", data);
        navigate("/career/jobs");
      } else {
        // Handle errors
        toast.error("Error posting job");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="content">
        <div className="card col-md-12 m-auto p-30">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-15">
                <label
                  htmlFor="name"
                  className="col-sm-4 col-form-label text-right"
                >
                  Name
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="text-danger">Name is required.</span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="title"
                  className="col-sm-4 col-form-label text-right"
                >
                  Job Title
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    {...register("title", { required: true })}
                  />
                  {errors.title && (
                    <span className="text-danger">Job Title is required.</span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="description"
                  className="col-sm-4 col-form-label text-right"
                >
                  Description
                </label>
                <div className="col-sm-6">
                  <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                  {errors.description && isDirty && !description.trim() && (
                    <p className="text-danger">Description is required.</p>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="designation"
                  className="col-sm-4 col-form-label text-right"
                >
                  Designation
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    name="designation"
                    className="form-control"
                    {...register("designation", { required: true })}
                  />
                  {errors.designation && (
                    <span className="text-danger">
                      Designation is required.
                    </span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="min_experience"
                  className="col-sm-4 col-form-label text-right"
                >
                  Min Experience
                </label>
                <div className="col-sm-3">
                  <input
                    type="number"
                    name="min_experience"
                    className="form-control"
                    {...register("min_experience", { required: true })}
                  />
                  {errors.min_experience && (
                    <span className="text-danger">
                      Min Experience is required.
                    </span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="max_experience"
                  className="col-sm-4 col-form-label text-right"
                >
                  Max Experience
                </label>
                <div className="col-sm-3">
                  <input
                    type="number"
                    name="max_experience"
                    className="form-control"
                    {...register("max_experience", { required: true })}
                  />
                  {errors.max_experience && (
                    <span className="text-danger">
                      Max Experience is required.
                    </span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="min_salary"
                  className="col-sm-4 col-form-label text-right"
                >
                  Min Salary
                </label>
                <div className="col-sm-3">
                  <input
                    type="number"
                    name="min_salary"
                    className="form-control"
                    {...register("min_salary", { required: true })}
                  />
                  {errors.min_salary && (
                    <span className="text-danger">Min Salary is required.</span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="max_salary"
                  className="col-sm-4 col-form-label text-right"
                >
                  Max Salary
                </label>
                <div className="col-sm-3">
                  <input
                    type="number"
                    name="max_salary"
                    className="form-control"
                    {...register("max_salary", { required: true })}
                  />
                  {errors.max_salary && (
                    <span className="text-danger">Max Salary is required.</span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <div className="col-sm-12 text-center">
                  <button
                    className="btn btn-primary btn-round mr-5"
                    type="submit"
                  >
                    Post Job
                  </button>
                  <Link
                    to="/career/jobs"
                    className="btn btn-primary btn-round"
                    type="button"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostJob;
