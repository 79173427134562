import React from "react";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from "./layouts/Main";
import Login from "./auth/Login";
import Dashboard from "./views/Dashboard";
import ChangePassword from "./views/users/ChangePassword";
import Profile from "./views/users/Profile";
import Contacts from "./views/settings/Contacts";
import SocialLinks from "./views/settings/SocialLinks";
import ContactRequests from "./views/contact-requests/ContactRequests";
import ListWorks from "./views/our-work/ListWorks";
import SeoContent from "./views/seo/seo-content";
import EbookSubscribers from "./views/e-book/EbookSubscribers";
import Jobs from "./views/career/Jobs";
import PostJob from "./views/career/PostJob";
import UpdateSeo from "./views/seo/UpdateSeo";
import Logout from "./auth/Logout";
import ViewContactRequest from "./views/contact-requests/View";
import AddWorks from "./views/our-work/AddWorks";
import ViewWorks from "./views/our-work/ViewWorks";
import UpdateWorks from "./views/our-work/UpdateWorks";
import UpdateSocialLinks from "./views/settings/UpdateSocialLinks";
import CreateRole from "./views/user-management/roles/CreateRole";
import ListRoles from "./views/user-management/roles/ListRoles";
import ListPermissions from "./views/user-management/permission/ListPermissions";

import ListBlog from "./views/blogs/ListBlog";
import BlogCreate from "./views/blogs/BlogCreate";
import EditBlog from "./views/blogs/EditBlog";
import EditJob from "./views/career/EditJob";
import PreviewBlog from "./views/blogs/PreviewBlog";
import ViewJob from "./views/career/ViewJob";
// import PrivateRoute from "./PrivateRoute";

const PrivateRoute = ({ element }) => {
  const token = sessionStorage.getItem("token");
  console.log({ token });
  return token ? element : <Navigate to="/login" />;
};

const AppRoutes = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/" element={<Main />}>
          {/* Secure the Dashboard and Users routes */}
          <Route
            path="/dashboard"
            element={<PrivateRoute element={<Dashboard />} />}
          />

          {/* Users */}
          <Route
            path="/users/change-password"
            element={<PrivateRoute element={<ChangePassword />} />}
          />
          <Route
            path="/users/profile"
            element={<PrivateRoute element={<Profile />} />}
          />

          {/* Settings */}
          <Route
            path="/settings/contacts"
            element={<PrivateRoute element={<Contacts />} />}
          />
          <Route
            path="/settings/social-links"
            element={<PrivateRoute element={<SocialLinks />} />}
          />
          <Route
            path="/settings/social-links/:id"
            element={<PrivateRoute element={<UpdateSocialLinks />} />}
          />

          {/* Contact Requests */}
          <Route
            path="/contact-requests"
            element={<PrivateRoute element={<ContactRequests />} />}
          />
          <Route
            path="/contact-requests/:id"
            element={<PrivateRoute element={<ViewContactRequest />} />}
          />

          {/* Our Works */}
          <Route
            path="/our-works"
            element={<PrivateRoute element={<ListWorks />} />}
          />
          <Route
            path="/our-works/add"
            element={<PrivateRoute element={<AddWorks />} />}
          />
          <Route
            path="/our-works/:slug"
            element={<PrivateRoute element={<ViewWorks />} />}
          />
          <Route
            path="/our-works/update/:slug"
            element={<PrivateRoute element={<UpdateWorks />} />}
          />

          {/* SEO Content */}
          <Route
            path="/seo-content"
            element={<PrivateRoute element={<SeoContent />} />}
          />
          <Route
            path="/seo-content/update/:id"
            element={<PrivateRoute element={<UpdateSeo />} />}
          />

          {/* eBook Subscribers */}
          <Route
            path="/e-book-subscribers"
            element={<PrivateRoute element={<EbookSubscribers />} />}
          />

          {/* Jobs  */}
          <Route
            path="/career/jobs"
            element={<PrivateRoute element={<Jobs />} />}
          />
          <Route
            path="/career/post-job"
            element={<PrivateRoute element={<PostJob />} />}
          />
          <Route
            path="/career/edit/:id"
            element={<PrivateRoute element={<EditJob />} />}
          />
          <Route
            path="/career/view/:id"
            element={<PrivateRoute element={<ViewJob />} />}
          />

          {/* User Management */}
          {/* Roles */}
          <Route
            path="/user-management/roles"
            element={<PrivateRoute element={<ListRoles />} />}
          />
          <Route
            path="/user-management/roles/create"
            element={<PrivateRoute element={<CreateRole />} />}
          />

          {/* Blogs */}
          <Route
            path="/blogs"
            element={<PrivateRoute element={<ListBlog />} />}
          />
          <Route
            path="/blogs/create"
            element={<PrivateRoute element={<BlogCreate />} />}
          />
          <Route
            path="/blogs/edit/:id"
            element={<PrivateRoute element={<EditBlog />} />}
          />
          <Route
            path="/blogs/view/:id"
            element={<PrivateRoute element={<PreviewBlog />} />}
          />

          {/* Permissions */}
          <Route
            path="/user-management/permissions"
            element={<PrivateRoute element={<ListPermissions />} />}
          />
        </Route>
      </Routes>
    </HashRouter>
  );
};

// const AppRoutes = ({ isAuthenticated }) => {
//   return (
//     <HashRouter>
//       <Routes>
//         if {!isAuthenticated && (
//           <Route path="/login" element={<Login />} />
//         )}
//         else{(
//           <Route path="/" element={<Main />}>
//             <Route path="/dashboard" element={<Dashboard />} />

//             <Route path="/users/change-password" element={<ChangePassword />} />
//             <Route path="/users/profile" element={<Profile />} />

//             {/* Settings */}
//             <Route path="/settings/contacts" element={<Contacts />} />
//             <Route path="/settings/social-links" element={<SocialLinks />} />

//             {/* Contact Requests */}
//             <Route path="/contact-requests" element={<ContactRequests />} />

{
  /* Our Works */
}
<Route path="/our-works" element={<ListWorks />} />;

// {/* SEO Content */}
// <Route path="/seo-content" element={<SeoContent />} />
// <Route path="/seo-content/update/:id" element={<UpdateSeo />} />

//             {/* eBook Subscribers */}
//             <Route path="/e-book-subscribers" element={<EbookSubscribers />} />

//             {/* Openings  */}
//             <Route path="/career/openings" element={<Openings />} />
//             <Route path="/career/post-job" element={<PostJob />} />

//           </Route>
//         )}
//       </Routes>
//     </HashRouter>
//   );
// }

export default AppRoutes;
