import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import config from "../../../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";

const apiUrl = config.apiUrl;

const AddWorks = () => {
  const pageTitle = "Add New Work";
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const [selectedDate, setSelectedDate] = useState("");
  const userData = localStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;

  const uploadImage = async (data) => {
    const formData = new FormData();

    if (data.mediaType === "url") {
      formData.append("url", data.mediaFile);
    } else {
      formData.append("file", data.mediaFile[0]);
    }

    const response = await fetch(`${apiUrl}/upload`, {
      method: "POST",
      body: formData,
    });

    const responseData = await response.json();

    return responseData;
  };

  const onSubmit = async (data) => {
    data.project_date = selectedDate;

    data.created_by = user ? user.name : "";
    data.status = "active";

    // Add conditional logic based on media type
    if (data.mediaType === "url") {
      data.images = null;
      data.videos = null;
    } else if (data.mediaType === "image") {
      data.videos = null;
    } else if (data.mediaType === "video") {
      data.images = null;
    }

    const imageResult = await uploadImage(data);

    data.images = imageResult?.file_path;

    axios
      .post(`${config.apiUrl}/work`, data)
      .then((response) => {
        toast.success("Work added successfully");
        navigate("/our-works");
      })
      .catch((error) => {
        console.error("Error adding work:", error);
      });
  };

  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">{pageTitle}</h3>
              <div className="timeline-footer text-right">
                <Link
                  to="/our-works/add"
                  className="btn btn-primary btn-round btn-sm"
                >
                  Add Work
                </Link>
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Work Type
                  </label>
                  <div className="col-sm-3">
                    <select
                      name="type"
                      {...register("type", { required: true })}
                      className="form-control"
                    >
                      <option value={""}>Please Select</option>
                      <option value={"TECHNOLOGY"}>TECHNOLOGY</option>
                      <option value={"STRATEGY"}>STRATEGY</option>
                      <option value={"OMNICHANNEL"}>OMNICHANNEL</option>
                      <option value={"DIGITAL"}>DIGITAL</option>
                      <option value={"DESIGN"}>DESIGN</option>
                      <option value={"CONTENT"}>CONTENT</option>
                      <option value={"CASE STUDY"}>CASE STUDY</option>
                    </select>
                    {errors.type && (
                      <span className="text-danger">
                        Work Type is required.
                      </span>
                    )}
                  </div>
                </div>

                {/* <div className="row mb-15">
                  <label
                    htmlFor="client_name"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Client Name
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      name="client_name"
                      placeholder="Client Name"
                      {...register("client_name", { required: true })}
                    />
                    {errors.client_name && (
                      <span className="text-danger">
                        Client Name is required.
                      </span>
                    )}
                  </div>
                </div> */}

                {/* <div className="row mb-15">
                  <label
                    htmlFor="url"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Work URL
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      name="url"
                      placeholder="Work URL"
                    {...register("url", { required: true })}
                    />
                    {errors.url && (
                      <span className="text-danger">Work URL is required.</span>
                    )}
                  </div>
                </div> */}

                {/* <div className="row mb-15">
                  <label
                    htmlFor="mediaType"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Media Type
                  </label>
                  <div className="col-sm-3">
                    <select
                      className="form-control"
                      name="mediaType"
                      {...register("mediaType", { required: true })}
                    >
                      <option value="">Select Media Type</option>
                      <option value="image">Image</option>
                      <option value="video">Video</option>
                      <option value="url">URL</option>
                    </select>
                    {errors.mediaType && (
                      <span className="text-danger">
                        Media Type is required.
                      </span>
                    )}
                  </div>
                </div> */}

                <div className="row mb-15">
                  <label
                    htmlFor="mediaFile"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Project Image
                  </label>
                  <div className="col-sm-3">
                    <input
                      type={watch("mediaType") === "url" ? "text" : "file"}
                      className="form-control"
                      name="mediaFile"
                      placeholder="Project Image"
                      {...register("mediaFile", { required: true })}
                    />
                    {errors.mediaFile && (
                      <span className="text-danger">File is required.</span>
                    )}
                  </div>
                </div>
                {/* 
                <div className="row mb-15">
                  <label htmlFor="videos" className="col-sm-4 col-form-label text-right">Work Videos</label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="videos" placeholder="Work Videos" {...register('videos', { required: true })} />
                    {errors.videos && <span className="text-danger">Video is required.</span>}
                  </div>
                </div> */}

                <div className="row mb-15">
                  <label
                    htmlFor="title"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Title
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      placeholder="Title"
                      {...register("title", { required: true })}
                    />
                    {errors.title && (
                      <span className="text-danger">Title is required.</span>
                    )}
                  </div>
                </div>

                <div className="row mb-15">
                  <label
                    htmlFor="short_description"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Short Description
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      name="short_description"
                      placeholder="Short Description"
                      {...register("short_description", { required: true })}
                    />
                    {errors.short_description && (
                      <span className="text-danger">
                        Short Description is required.
                      </span>
                    )}
                  </div>
                </div>

                <div className="row mb-15">
                  <label
                    htmlFor="description"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Description
                  </label>
                  <div className="col-sm-5">
                    <textarea
                      type="text"
                      className="form-control"
                      name="description"
                      placeholder="Description"
                      {...register("description", { required: true })}
                    ></textarea>
                    {errors.description && (
                      <span className="text-danger">
                        Short Description is required.
                      </span>
                    )}
                  </div>
                </div>

                <div className="row mb-15">
                  <label
                    htmlFor="meta_title"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Meta Title
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      name="meta_title"
                      placeholder="Meta Title"
                      {...register("meta_title", { required: true })}
                    />
                    {errors.meta_title && (
                      <span className="text-danger">
                        Meta Title is required.
                      </span>
                    )}
                  </div>
                </div>

                <div className="row mb-15">
                  <label
                    htmlFor="meta_description"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Meta Description
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      name="meta_description"
                      placeholder="Meta Description"
                      {...register("meta_description", { required: true })}
                    />
                    {errors.meta_description && (
                      <span className="text-danger">
                        Meta Description is required.
                      </span>
                    )}
                  </div>
                </div>

                <div className="row mb-15">
                  <label
                    htmlFor="project_date"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Project Date
                  </label>
                  <div className="col-sm-3">
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      name="project_date"
                      placeholderText="Project Date"
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                    />

                    {errors.project_date && (
                      <>
                        {" "}
                        <br />
                        <span className="text-danger">
                          Project Date is required.
                        </span>
                      </>
                    )}
                  </div>
                </div>

                <div className="row mb-15">
                  <div className="col-sm-12 text-center">
                    <button
                      className="btn btn-primary btn-round mr-5"
                      type="submit"
                    >
                      Submit
                    </button>
                    <Link
                      to="/our-works"
                      className="btn btn-primary btn-round"
                      type="reset"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWorks;
