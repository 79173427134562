// import '../../assets/assets/';

const Dashboard = () => {
  return <>
    <div style={{ height: "600px" }}>

    </div>
  </>;
};

export default Dashboard;
